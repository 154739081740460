import Head from '../components/informational/Head'
import PreviewView from '../components/containers/PreviewView'
import React from 'react'
import { getSplat } from '../utils/commons/helpers'
import withRouter from '../components/hocs/withRouter'

const PreviewDownload = props => {
  // For some reason Gatsby is trying to build this page for offline, tmp fix:
  if (!process.browser) {
    return ''
  }

  const { method, target } = props
  const url = getSplat(props)
  return (
    <>
      <Head title='Preview Downloaded Website' description='Preview the website downloaded by websitedownloader.io' />
      <PreviewView url={url} method={method} target={target} />
    </>
  )
}

export default withRouter('/preview-download/:method/:target/*')(PreviewDownload)
